import styled, { css } from 'styled-components';
import { Paper } from '@material-ui/core';
import { UnFormDatePicker } from '~/components';

export const ContainerPromoCad = styled.div`
  margin-top: 30px;
`;

export const UploadFile = styled.input`
  width: 148px;

  &&::-webkit-file-upload-button {
    visibility: hidden;
  }
  &&::before {
    content: 'Selecione o arquivo';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }
  &&:hover::before {
    border-color: black;
  }
  &&:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
`;

export const ContentPromoCad = styled(Paper)`
  padding: 16px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  margin-left: -37px;
  margin-top: -24px;
  width: calc(100% + 60px);
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  background: #ffffff;
  padding: 20px 30px;
  position: sticky;
  top: 64px;
  z-index: 999;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.09);
`;

export const HeaderRightButtons = styled.div`
  display: flex;

  button + button {
    margin-left: 20px;
  }
`;

export const HeaderTitle = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  color: #000;

  & > svg {
    margin-right: 10px;
    cursor: pointer;
  }

  & > h2 {
    font-weight: 400;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
`;

export const Section = styled.div`
  &:not(:first-child) {
    margin-top: 30px;
  }
`;

export const SectionTitle = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 15px;
    cursor: pointer;
  }

  h3 {
    font-weight: 400;
    font-size: 20px;
    text-decoration: underline;
  }

  button {
    margin-left: 10px;
  }
`;

export const SectionContent = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  margin-top: 10px;

  ${props =>
    props.loading === true &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        display: block;
        z-index: 98;
        margin: -20px;
        border-radius: 6px;
      }

      .MuiCircularProgress-root {
        position: absolute;
        z-index: 99;
      }
    `}

  &.ebooks, &.sweepstakes {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 15px;
    background-color: transparent;
    padding-left: 0px;
    padding-right: 0px;
    box-shadow: none;
  }
`;

export const SweepstakeItemContainer = styled.div`
  position: relative;
  width: 100%;
  filter: ${({ isDraftPending }) =>
    isDraftPending ? 'grayscale(100%)' : 'none'};
  background-color: ${({ isDraftPending, isApprovePending }) => {
    if (isDraftPending) {
      return 'lightgray';
    }

    if (isApprovePending) {
      return 'yellow';
    }

    return '#fff';
  }};
  border-radius: 6px;
  padding: 4px;

  ${props =>
    props.isDragging === true &&
    css`
      opacity: 0;
    `}

  ${props =>
    props.loading === true &&
    css`
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        background-color: rgba(255, 255, 255, 0.95);
        z-index: 98;
        position: absolute;
        border-radius: 6px;
      }

      .MuiCircularProgress-root {
        position: absolute;
        z-index: 99;
        left: 50%;
        top: 50%;
        margin-left: -30px;
        margin-top: -30px;
      }
    `}

  header {
    position: relative;
  }

  p {
    font-weight: bold;
    font-size: 14px;
    padding: 5px;
  }

  span.price {
    position: absolute;
    background: #fff;
    font-size: 14px;
    font-weight: bold;
    right: 0;
    bottom: 0px;
    padding: 6px;
    border-top-left-radius: 6px;
    /* border-bottom-right-radius: 6px; */
  }

  div.winners {
    position: absolute;
    background: #fff;
    font-size: 14px;
    font-weight: bold;
    left: 0;
    top: 0;
    padding: 6px;
    /* border-top-left-radius: 6px; */
    border-bottom-right-radius: 6px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }

  div.actions {
    position: absolute;
    background: #fff;
    padding: 6px;
    top: 0;
    right: 0;
    border-bottom-left-radius: 6px;
    /* border-top-right-radius: 6px; */

    button + button {
      margin-left: 10px;
    }
  }
`;

export const EbookItemContainer = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 6px;
  padding: 4px;

  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  div.order {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 6px;

    h4 {
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
    }

    span {
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
    }

    div {
      display: flex;
      align-items: center;

      a {
        color: #757de8;
        text-decoration: none;
      }
    }
  }

  ${props =>
    props.isDragging === true &&
    css`
      opacity: 0;
    `}

  ${props =>
    props.loading === true &&
    css`
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        background-color: rgba(255, 255, 255, 0.95);
        z-index: 98;
        position: absolute;
        border-radius: 6px;
      }

      .MuiCircularProgress-root {
        position: absolute;
        z-index: 99;
        left: 50%;
        top: 50%;
        margin-left: -30px;
        margin-top: -30px;
      }
    `}
`;

export const Thumbnail = styled.div`
  width: 160px;
  height: 160px;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SweepstakeFilterContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;

  button + button {
    margin-left: 5px;
  }
`;

export const ButtonFilter = styled.button`
  padding: 5px;
  font-size: 14px;
  background-color: #fff;
  border: 1px dotted rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  display: flex;
  align-items: center;
  opacity: 0.45;

  svg {
    font-size: 16px;
    margin-right: 5px;
  }

  ${props =>
    props.active === true &&
    css`
      opacity: 1;
    `}
`;

export const HistoryContainer = styled.div`
  width: 450px;
  padding: 15px;
  max-height: 500px;
  overflow: auto;

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .empty-logs {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
  }

  .loading-container + ul.logs-container {
    margin-top: 15px;
  }

  ul.logs-container {
    list-style-type: none;
    display: flex;
    flex-direction: column;

    li {
      padding: 4px 0px;

      &:first-child {
        padding-top: 0px;
      }

      &:last-child {
        padding-bottom: 0px;
      }

      .log-action .detail {
        margin: 5px 0px;
        padding: 5px;
        background-color: #333;
        color: #e8e8e8;
        border-radius: 4px;

        pre {
          overflow: auto;
        }
      }

      .log-action .info {
        display: flex;
        font-size: 15px;
        font-weight: 400;
        align-items: center;

        span.action {
          font-style: italic;
          margin-left: 10px;
          margin-right: 10px;
          flex: 1;
          cursor: pointer;
        }

        span.date {
          font-size: 12px;
        }
      }
    }
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${({ isReturn }) =>
    isReturn ? 'space-between' : 'flex-end'};
`;

export const FormError = styled.span`
  color: #f44336;
  font-size: 0.75rem;
  margin-left: 14px;
  margin-right: 14px;
  margin-top: 3px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
`;

export const DateTimePicker = styled(UnFormDatePicker)`
  & + & {
    margin-top: 15px;
  }
`;
