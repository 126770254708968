import { shape, number, string, arrayOf, oneOf } from 'prop-types';

const tipoSorteioPropTypes = shape({
  idTipoSorteio: number.isRequired,
  codigo: string.isRequired,
  descricao: string.isRequired,
  status: number.isRequired,
  dataInsert: string.isRequired,
  dataUpdate: string.isRequired,
});

const imagemPropTypes = shape({
  idImagensSorteio: number.isRequired,
  urlImagem: string.isRequired,
  urlVideo: string,
  descricao: string.isRequired,
  status: number.isRequired,
  dataInsert: string.isRequired,
  dataUpdate: string.isRequired,
});

const ganhadoresPropTypes = arrayOf(
  shape({
    numeroGanhadores: number.isRequired,
  })
);

export const dataPropTypes = shape({
  idSorteio: number.isRequired,
  descricao: string.isRequired,
  valorSugerido: number.isRequired,
  numeroSerie: number.isRequired,
  numeroPlano: number.isRequired,
  valorCapitalizacao: number,
  ordem: number.isRequired,
  qtdPremiosDisponiveis: number.isRequired,
  qtdDesbloqueiaPremio: number.isRequired,
  qtdPremioEntrega: number.isRequired,
  classificacao: number.isRequired,
  codigo: number.isRequired,
  status: number.isRequired,
  tipo: tipoSorteioPropTypes.isRequired,
  imagem: imagemPropTypes.isRequired,
  imagemPremio: imagemPropTypes.isRequired,
  dezenas: oneOf([null, arrayOf(number)]),
  ganhadores: ganhadoresPropTypes.isRequired,
  index: number.isRequired,
  dataLoteria: string,
});
